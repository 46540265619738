// import React from "react";
// import { Typography, Box, Grid, Paper, IconButton } from "@mui/material";
// import { Link } from "react-router-dom";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { stationMetaData } from "../../utils/rawData"; // Import the stationMetaData array
// import { getStatusIcon } from "../../utils/rawFunction";
// function AllStationsList() {
// 	return (
// 		<Box sx={{ p: 2, maxWidth: "100%", margin: "auto", overflow: "auto" }}>
// 			<Grid container spacing={2}>
// 				{stationMetaData.map((station) => (
// 					<Grid item xs={12} key={station.id}>
// 						<Paper
// 							elevation={0}
// 							sx={{
// 								p: 1,
// 								display: "flex",
// 								justifyContent: "space-between",
// 								alignItems: "center",
// 								borderBottom: "1px solid #e0e0e0", // Line at the end
// 								overflow: "hidden",
// 							}}
// 						>
// 							<Box sx={{ display: "flex", alignItems: "center" }}>
// 								<Box sx={{ ml: 1 }}>{getStatusIcon(station.status)}</Box>
// 								<Box sx={{ ml: 1 }}>
// 									<Typography
// 										variant="body1"
// 										sx={{ fontWeight: "bold", fontSize: "0.9rem" }}
// 									>
// 										{station.name}
// 									</Typography>
// 									<Typography
// 										variant="body2"
// 										color="text.secondary"
// 										sx={{ fontSize: "0.8rem" }}
// 									>
// 										{station.id}
// 									</Typography>
// 								</Box>
// 							</Box>
// 							<IconButton
// 								component={Link}
// 								to={`/${station.id}`}
// 								size="small"
// 								sx={{
// 									border: "1px solid #e0e0e0",
// 									borderRadius: "50%",
// 									padding: "4px",
// 									"&:hover": {
// 										backgroundColor: "transparent",
// 									},
// 								}}
// 							>
// 								<ArrowForwardIosIcon sx={{ fontSize: "0.8rem" }} />
// 							</IconButton>
// 						</Paper>
// 					</Grid>
// 				))}
// 			</Grid>
// 		</Box>
// 	);
// }

// export default AllStationsList;

import React, { useState } from "react";
import {
  Typography,
  Box,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { stationMetaData } from "../../utils/rawData";
import { createUrlFriendlyName, getStatusIcon } from "../../utils/rawFunction";
import { useMemo } from "react";
import { useTranslation } from "../../context/TranslationContext";

function AllStationsList({ onStationSelect }) {
  const [expandedPanels, setExpandedPanels] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { translate, currentLanguage } = useTranslation();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanels((prevExpanded) =>
      isExpanded
        ? [...prevExpanded, panel]
        : prevExpanded.filter((p) => p !== panel)
    );
  };
  const communities = useMemo(() => {
    // Helper function to check if a station should be in R&D category
    const isResearchStation = (station) => {
      return (
        !station.Community || // handles null
        station.Community.trim() === ""
      );
    };

    // First, separate research stations
    const researchStations = stationMetaData.filter(isResearchStation);

    // Remove research stations from main grouping
    const regularStations = stationMetaData.filter(
      (station) => !isResearchStation(station)
    );

    // Group regular stations by community
    const grouped = regularStations.reduce((acc, station) => {
      const { Community } = station;
      if (!acc[Community]) {
        acc[Community] = [];
      }
      acc[Community].push(station);
      return acc;
    }, {});

    // Convert to array and sort
    const sortedCommunities = Object.entries(grouped)
      .map(([community, stations]) => ({
        name: community,
        stations: stations.sort((a, b) => a.name.localeCompare(b.name)),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    // Add research stations as last category if there are any
    if (researchStations.length > 0) {
      sortedCommunities.push({
        name: "Research & Development",
        stations: researchStations.sort((a, b) => a.name.localeCompare(b.name)),
      });
    }

    return sortedCommunities;
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        p: isMobile ? 1 : 2,
      }}
    >
      {communities.map((community, index) => (
        <React.Fragment key={index}>
          {index === communities.length - 1 &&
            community.name === "Research & Development" && (
              <Box
                sx={{
                  my: 2,
                  borderTop: "1px solid",
                  borderColor: "divider",
                }}
              />
            )}
          <Accordion
            key={index}
            expanded={expandedPanels.includes(`panel${index}`)}
            onChange={handleChange(`panel${index}`)}
            disableGutters
            elevation={0}
            sx={{
              "&:before": {
                display: "none",
              },
              "& .MuiAccordionSummary-root": {
                minHeight: isMobile ? 48 : 56,
                px: isMobile ? 1 : 2,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                flexDirection: "row-reverse",
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                  transform: "rotate(-90deg)",
                },
                "& .MuiAccordionSummary-content": {
                  marginLeft: "1rem",
                },
              }}
            >
              <Typography
                variant={isMobile ? "subtitle1" : "h6"}
                sx={{
                  fontSize: isMobile ? "0.9rem" : "1.25rem",
                  fontWeight: "medium",
                }}
              >
                {translate(community.name)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: isMobile ? 1 : 2 }}>
              <Grid container spacing={isMobile ? 1 : 2}>
                {community.stations.map((station) => (
                  <Grid item xs={12} key={station.id}>
                    <Box
                      sx={{
                        p: isMobile ? 0.5 : 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "background.paper",
                        borderRadius: 1,
                        // '&:hover': {
                        //   backgroundColor: 'action.hover'
                        // }
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          minWidth: 0, // Ensures text truncation works
                        }}
                      >
                        <Box sx={{ ml: isMobile ? 0.5 : 1 }}>
                          {getStatusIcon(station.status)}
                        </Box>
                        <Box
                          sx={{
                            ml: isMobile ? 0.5 : 1,
                            overflow: "hidden",
                            flex: 1,
                          }}
                        >
                          <Typography
                            component={Link}
                            to={`/${createUrlFriendlyName(station.name)}`}
                            onClick={onStationSelect}
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              fontSize: isMobile ? "0.8rem" : "0.9rem",
                              textDecoration: "none",
                              color: "inherit",
                              display: "block",
                              overflow: "hidden",
                              // textOverflow: 'ellipsis',
                              // whiteSpace: 'nowrap',
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {translate(station.name)}
                          </Typography>
                        </Box>
                      </Box>
                      <IconButton
                        component={Link}
                        to={`/${createUrlFriendlyName(station.name)}`}
                        onClick={onStationSelect}
                        size="small"
                        sx={{
                          border: "1px solid #e0e0e0",
                          borderRadius: "50%",
                          padding: isMobile ? "2px" : "4px",
                          ml: 1,
                          flexShrink: 0,
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                          },
                        }}
                      >
                        <ArrowForwardIosIcon
                          sx={{ fontSize: isMobile ? "0.7rem" : "0.8rem" }}
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      ))}
    </Box>
  );
}

export default AllStationsList;
